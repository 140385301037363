import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDWWaG8TiFXkcjp_rTaI9STh33fLq0_c58",
  authDomain: "ldt-form.firebaseapp.com",
  projectId: "ldt-form",
  storageBucket: "ldt-form.appspot.com",
  messagingSenderId: "694925011581",
  appId: "1:694925011581:web:6a7296ae1d93919b415f14"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialise Firestore
const db = getFirestore(app);

export { db };
