import React from 'react';
import './CSS/App.css';
import Countdown from 'react-countdown';
import concoursLabel from './img/label-concours-ldt-01.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';
import EmailCaptureForm from './components/EmailCaptureForm';


// Renderer pour personnaliser l'affichage du compte à rebours
const CountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <span>Le concours a commencé !</span>;
  } else {
    return (
      <div className="countdown-wrapper">
        <div className="countdown-item">
          {days}
          <span className="countdown-label">jours</span>
        </div>
        <div className="countdown-item">
          {hours}
          <span className="countdown-label">heures</span>
        </div>
        <div className="countdown-item">
          {minutes}
          <span className="countdown-label">minutes</span>
        </div>
        <div className="countdown-item">
          {seconds}
          <span className="countdown-label">secondes</span>
        </div>
      </div>
    );
  }
};


function App() {
  const socialLinks = {
    instagram: 'https://www.instagram.com/ldtgiveaway',
    tiktok: 'https://www.tiktok.com/@ldt.giveaway',
    youtube: 'https://www.youtube.com/@Cedrift370z',
    facebook: 'https://www.facebook.com/profile.php?id=61557263626898&mibextid=LQQJ4d'
  };
  return (
    <div className="App">
      {/* <h1>Grand Concours Annuel</h1> */}
      <img className="concour-label  countdown-time" src={concoursLabel} alt="" />
      <p>
        Gagne ta voiture de rêve ! 🍀<br/>
         Win your dream car ! 🍀<br/>
         24-04-24
      </p>
      <div className="countdown">
        <Countdown 
          date={new Date('04/24/2024 12:00:00')}
          renderer={CountdownRenderer}
        />
      </div>
      <div>
        <p>Me notifier au début du concours !</p>
        <EmailCaptureForm/>
      </div>
      <div className="flex-col">
          <p>Restez informé ! Suivez-nous pour des mises à jour exclusives!</p>

        <div className="social-links">
          <a href={socialLinks.instagram} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href={socialLinks.tiktok} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTiktok} />
          </a>
          <a href={socialLinks.youtube} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faYoutube} />
          </a>
          <a href={socialLinks.facebook} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} />
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;

