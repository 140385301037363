import React, { useState } from 'react';
import { db } from './firebase-config';
import { collection, addDoc } from 'firebase/firestore';

function EmailCaptureForm() {
  const [email, setEmail] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const docRef = await addDoc(collection(db, "emails"), {
        email: email,
      });
      console.log("Document written with ID:", docRef.id); // Maintenant docRef est défini
      alert("Merci de votre inscription !"); // Ajoutez une alerte ou une autre forme de confirmation visuelle
      setEmail(""); // Réinitialisez le champ email après l'envoi
    } catch (e) {
      console.error("Error adding document: ", e);
      alert("Une erreur s'est produite. Veuillez réessayer."); // Notification d'erreur
    }
  };
  
  

  return (
    <form onSubmit={handleSubmit} className='form-email'>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Entrez votre e-mail"
        required
      />
      <button type="submit">ME NOTIFIER</button>
    </form>
  );
}

export default EmailCaptureForm;
